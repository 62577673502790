.info {
  position: relative;
  z-index: 100;
  padding-top: 160px;
  &-result {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & a {
      margin-right: 12px;
    }
  }
  &-book {
    display: flex;
    margin-bottom: 20px;
    position: relative;
    z-index: 10;
    &__block {
      padding: 0;
      display: flex;
      flex-direction: column;
      &:nth-child(2) {
        padding-left: 30px;
      }
    }
    &__collect {
      padding-left: 0 !important;
      align-self: flex-end;
    }
    &__col-img {
      margin-bottom: 0 !important;
    }
    &__img {
      // height: 458px;
      &-photo {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: contain;
      }
    }
    &__name {
      font-weight: bold;
      font-size: 48px;
      color: #fff;
      margin-bottom: 20px;
    }
    &__drop {
      flex: 1;
      position: relative;
    }
    &__img,
    .detail {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: rgba(26, 26, 26, 0.6);
      backdrop-filter: blur(40px);
      border-radius: 20px;
      padding: 20px;
    }
    .detail {
      position: relative;
      &-book_type {
        display: flex;
        position: absolute;
        height: 20px;
        right: 20px;
        top: -13px;
      }
      &-item {
        display: flex;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        color: #fff;
        &__left {
          font-weight: bold;
        }
        &__right {
          margin-left: 10px;
          &--purple {
            color: $price;
          }
        }
        &__rating {
          display: flex;
          & div {
            height: auto;
            margin-bottom: 0;
            & span {
              font-size: 15px !important;
            }
          }
          &-span {
            margin-left: 5px;
          }
        }
        &__type {
          outline: none;
          border: none;
          background: transparent;
          color: $price;
          &:not(:first-child) {
            margin-left: 5px;
          }
        }
      }
    }
    &__buttons {
      display: flex;
      padding: 0 0 0 30px;
    }
    &__hidden {
      display: none;
    }
  }
  &-block {
    padding: 20px;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
  }
  .chat {
    margin-bottom: 20px;
    &__user {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
  .comment {
    display: flex;
    margin-bottom: 50px;
    &__input {
      flex: 9;
      &::placeholder {
        color: #999999;
      }
    }
    &__button {
      flex: 3;
      height: auto;
      margin-left: 30px;
    }
  }
}
.collect {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-detail {
    flex: 1;
    margin-bottom: 0 !important;
  }
}
.button-tag {
  flex: 1;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  padding: 14px 0;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  height: 50px;
  &_purple {
    background: $price;
  }
  &_gray {
    background: #999999;
  }
  &_green {
    background: $green;
    margin-left: 30px !important;
  }
  &_only {
    margin-left: 0;
  }
  &__img {
    margin-right: 20px;
  }
}
.drop {
  flex: 1;
  position: relative;
  &-select {
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;
    cursor: pointer;
  }
  &__ul {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: $bg;
    backdrop-filter: blur(40px);
    padding-top: 47px;
    border-radius: 20px;
  }
  &__li {
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;
    color: #fff;
    cursor: pointer;
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
