.footer {
  min-height: 270px;
  background: rgba(26, 26, 26, 0.6);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(40px);
  border-radius: 20px 20px 0px 0px;
  padding-bottom: 30px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-top {
    display: flex;
    padding-top: 30px;
    &__block {
      display: flex;
      margin: 0 auto;
    }
    .navs {
      width: 255px;
      margin-right: 30px;
      &__title {
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        color: #fff;
        margin-bottom: 30px;
      }
      &__ul {
        margin: 0 !important;
      }
      &__li {
        margin-bottom: 15px;
      }
      &__link {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #fff;
      }
    }
  }
  &-hidden {
    display: none;
    margin: 30px 0;
    justify-content: center;
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 33px;
    &__organization,
    &__developers {
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      order: 3;
    }
    &__link {
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
    &__blank {
      margin-left: 22px;
    }
  }
}
.policy {
  padding-top: 150px;
  &-text {
    color: #fff;
    font-size: 16px;
    padding: 20px;
    line-height: 26px;
  }
  &_wrap {
    display: flex;
    justify-content: center;
  }
  &_header {
    text-align: center;
    max-width: 400px;
  }
}
