.header {
  position: relative;
  z-index: 120;
  height: 100vh !important;
  min-height: 600px;
  &-wrap {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
  }
  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 100px;
    flex: 2;
    max-width: 500px;
    &__text {
      font-weight: 900;
      font-size: 48px;
      margin: 0px 20px 20px;
      color: #fff;
      max-width: 500px;
    }
    &__button {
      margin: 0px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-right {
    flex: 1;
    overflow: hidden;
    &__img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain !important;
    }
    .swiper-slide {
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain !important;
      }
    }
  }
}
