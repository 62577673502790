.user {
  position: relative;
  z-index: 100;
  padding-top: 160px;
  overflow-x: hidden;
  &-present {
    margin-bottom: 20px;
  }
  .level {
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    &-wrap {
      flex: 1;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
    &-item {
      position: relative;
      background: $bg;
      height: 20px;
      margin-bottom: 13px;
      border-radius: 10px;
      &__insider {
        border-radius: 10px;
        width: 0;
        height: 100%;
        background: $orange;
        transition: all 0.3s ease-out;
      }
    }
    &__name {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #999999;
      text-align: center;
    }
  }
  &-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    .link {
      padding: 20px;
      min-height: 133px;
      margin-bottom: 40px;
      &__title {
        font-weight: bold;
        font-size: 24px;
        color: #fff;
        margin-bottom: 20px;
      }
      &__url {
        font-size: 14px;
        line-height: 22px;
        color: #747ca5;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        background: transparent;
      }
    }
  }
  .proccess {
    padding: 20px;
    margin-bottom: 20px;
    &__title {
      font-weight: bold;
      font-size: 48px;
      line-height: 36px;
      color: #fff;
      margin-bottom: 20px;
      & span {
        color: $price;
      }
    }
    &-item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 22px;
      color: #fff;
      margin-bottom: 10px;
      &--orange {
        color: $orange;
        margin: 20px 0 0;
      }
    }
    .cashback {
      display: flex;
      align-items: center;
      margin-top: 20px;
      &-wrap {
        display: flex;
        align-items: center;
        height: 100%;
      }
      &-title {
        color: #fff;
        font-weight: bold;
        font-size: 24px;
      }
      &-money {
        font-weight: bold;
        font-size: 32px;
        color: #33d160;
        margin-left: 30px;
      }
      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        max-width: 290px;
        width: 290px;
        background: #747ca5;
        color: #fff;
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        font-weight: bold;
        font-size: 14px;
        margin-left: 30px;
        & img {
          margin-right: 20px;
        }
      }
    }
  }
  .stats {
    display: flex;
    &-block {
      flex: 1;
      height: 200px;
      text-align: center;
      padding: 43px 45px;
      font-weight: bold;
      font-size: 24px;
      color: #fff;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
    &-title {
      margin-bottom: 15px;
    }
    &-number {
      & span {
        color: $orange;
      }
    }
  }
  &__copy {
    width: 100%;
  }
}
